@font-face {
font-family: "AveriaSansLibre-Italic";
src: local("AveriaSansLibre-Italic"),url("./fonts/AveriaSansLibre-Italic.ttf") format("truetype");
font-weight: 400;
}
@font-face {
    font-family: "Barracuda-Bold";
    src: local("Barracuda-Bold"),url("./fonts/Barracuda-Bold.ttf") format("truetype");
    font-weight: 400;
    }
    @font-face {
        font-family: "Barracuda-Regular";
        src: local("Barracuda-Regular"),url("./fonts/Barracuda-Regular.ttf") format("truetype");
        font-weight: 400;
        }
        @font-face {
            font-family: "Trap-Medium";
            src: local("Trap-Medium"),url("./fonts/Trap-Medium.otf") format("opentype");
            font-weight: 400;
            }
            @font-face {
                font-family: "Trap-Bold";
                src: local("Trap-Bold"),url("./fonts/Trap-Bold.otf") format("opentype");
                font-weight: 400;
                }
                @font-face {
                    font-family: "Trap-Regular";
                    src: local("Trap-Regular"),url("./fonts/Trap-Regular.otf") format("opentype");
                    font-weight: 400;
                    }
                    body{
                     font-family: "Trap-Medium";
                    }
                    @media only screen and (max-width: 1200px) {
                        .hidden{
                            display: none !important;
                        }
                        .visiblemobile{
                            display: none !important;
                        }
                          } 

                          .visibleonmobile{
                            display: block !important;
                        } 
                
                          .hidden{
                            display:flex;
                        }
.bg{
    z-index: 0;
position: absolute;
width: 50vw;
height: 80vh;
right: -80px;
top: -80px;
background: linear-gradient(102.03deg, rgba(255, 96, 96, 0.2) 7.27%, rgba(212, 131, 225, 0.2) 107.78%);
filter: blur(557px);
}
.bg-color{
background: linear-gradient(102.03deg, rgba(255, 96, 96, 0.2) 7.27%, rgba(212, 131, 225, 0.2) 107.78%);
}
.bg-fresh{
    background:-webkit-linear-gradient(right, #D483E1 7% ,#FF6060 100%);
}

.border{
    outline: 0;
    border-width: 0 0 2px;
    border-color: white;
}
.border::placeholder{
    color: white;
}
.item {
    -webkit-user-select: none;  
    -moz-user-select: none;    
    -ms-user-select: none;      
    user-select: none;
  }
.linear{
font-family: "Barracuda-Regular";
display: inline;
background:-webkit-linear-gradient(right, #FF6060 7.27%, #D483E1 107.78%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
}
.linear-lean{
font-family: "Barracuda-Regular";
    display: inline-block;
    background:-webkit-linear-gradient(right, #D483E1 50%,#FF6060 150%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    }