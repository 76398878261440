
.why-text {
    font-size: 32px;
    padding-right: 24vw;
}


.size{
    width: 10%;
}

.whodiv{
    width: 70vw;
    padding: 20px;
}
.title{
    font-size:3.4vw;
}
.subtitle{
    font-size:25px;
}
@media only screen and (max-width: 1200px) {
    .why-text {
        font-size: 32px;
        padding-right: 12vw;
    }
    .size{
        width: 30%;
    }
    .whodiv{
        width: 90vw;
        padding: none;
    }
    .title{
        font-size: 48px;
    }
    .subtitle{
        font-size: 18px;
    }
} 