
.hero {
    padding-left: 10vw;
    padding-right: 10vw;
    padding-top: 80px;
    padding-bottom: 100px;
}

.hero-text {
    font-size: 92px;
}

.hero-sub-text {
    font-size: 24px;
}

.scroll-down-text {
    font-size: 18px;
}

.text-container {
    padding-top: 60px;
}

@media only screen and (max-width: 1200px) {
    .hero {
        padding-left: 50px;
        padding-right: 50px;
        padding-top: 80px;
        padding-bottom: 50px;

    }
    .text-container {
        padding-top: 30px;
    }
    .hero-text {
        font-size: 60px;
    }
    .hero-sub-text {
        font-size: 20px;
    }
} 