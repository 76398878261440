.col{
    flex-direction: row;
}
.col1{
    width:40%;
}
.col2{
    width:60%;
}
@media only screen and (max-width: 1200px) {
    .col{
        flex-direction: column;
    }
    .col1{
        width:100%;
    }
    .col2{
        width:100%;
        padding-top: 50px;

    }
} 