    .bttn-color{
        background:-webkit-linear-gradient(right, #D483E1 7% ,#FF6060 100%);
    }
    .bttn-color2{
        background: rgb(255,96,96);
        background: linear-gradient(90deg, rgba(255,96,96,1) 35%, rgba(212,131,225,1) 100%);
    }
    .logo{
        flex:0.8;
        align-items: center;
        justify-content: center;
    }
    .links{
        display:flex;
    }
    .logo2{
        width: 18%;
    }
    .button{
        flex: 1,
    }

    .nav {
        padding-left: 100px;
        padding-right: 100px;
    }
    
  @media only screen and (max-width: 1200px) {
    .links{
        display:none;
    }
    .logo2{
        width: 100px;
        margin:auto;
    }
    .nav {
        padding-left: 50px;
        padding-right: 50px;
    }
    .logo{
        justify-content: flex-start;
    }
    .button{
        justify-content: flex-end;

    }
  } 

    
